<template >
  <div>
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.kpTemplate"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      
      <div class="invoice-box" style="position: relative">
        <!-- header -->
          <div v-html="header"></div>
              
          <hr>   
          <div>
              <div class="" style="width: 100%;font-size: 10px;margin: 15px 0px;">
                  <div style="float:left">Date: 22-02-2022</div>
                  <div style="float:right">
                      Offer № 511 <br>
                  </div>
              </div>
          </div>
        
          <div  style="text-align: center;">
            <div style="font-size: 16px;color: #000;">Commercial offer</div>
            <span style="font-size: 9px;color: #d3d3d3;">From thread to clothing</span>
          </div>
          <table  style="width: 100%;" cellpadding="1" cellspacing="1" class="N_table">
              <tr class="heading" style="font-size: 9px; font-family: 'DejaVu Sans';">
                  <td class="N_td" style="text-align:center;width: 5%;max-width: 10%;vertical-align: middle !important;line-height: 12px;"> №</td>
                  <td class="N_td" style="text-align:center;width: 17%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Продукт</td>
                  <td class="N_td" style="text-align:center;width: 7%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Кол-во</td>
                  <td class="N_td" style="text-align:center;width: 5%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Изм</td>
                  <td class="N_td" style="text-align:center;width: 10%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Цена</td>
                  <td class="N_td" style="text-align:center;width: 5%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Валют</td>
                  <td class="N_td" style="text-align:center;width: 5%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Скидка (%) </td>
                  <td class="N_td" style="text-align:center;width: 5%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">НДС (%) </td>
                  <td class="N_td" style="text-align:center;width: 10%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Цвет</td>
                  <td class="N_td" style="text-align:center;width: 10%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Упаковка</td>
                  <td class="N_td" style="text-align:center;width: 10%;max-width: 10%;vertical-align: middle !important;line-height: 12px;">Сумма (USD)</td>
              
              </tr>
              <tr style="font-size: 10px; font-family: 'DejaVu Sans';">
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle;">1</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">name</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">quantity</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">measurement</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">32143214</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">SUM</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">10</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">5</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';vertical-align:middle"> red
                          <div style="display:inline-block; width: 10px; height: 7px; background-color: red"></div>
                      </td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">package</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">312 408 329 $</td>
              </tr>
              <tr style="font-size: 10px; font-family: 'DejaVu Sans';">
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle;">2</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">name</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">quantity</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">measurement</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">32143214</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">SUM</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">10</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">5</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';vertical-align:middle"> red
                          <div style="display:inline-block; width: 10px; height: 7px; background-color: red"></div>
                      </td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">package</td>
                      <td class="N_td" style="font-family: 'DejaVu Sans';text-align: center;vertical-align:middle">312 408 329 $</td>
              </tr>
          </table>
          <br>
          
          <!-- footer -->
          <div v-html="footer"></div>
          
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  
  mixins: [form, drawer],
  props: {
    header: {
      default: ''
    },
    footer: {
      default: ''
    }
  },
  data() {
    return {
        
    };
  },
  
  created() {},
  watch: {
      header: {
        handler: function(val) {
        },
        deep: true,
        immediate: true
      },
      footer: {
        handler: function(val) {
          
        },
        deep: true,
        immediate: true
      },
  },
  computed: {
      ...mapGetters({
          rules: "kpTemplates/rules",
          model: "kpTemplates/model",
          columns: "kpTemplates/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "kpTemplates/store",
      }),
      stringToHtml(str){
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, 'text/html');
        return doc.body;
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>

<style>
    .invoice-box {
        width: 297mm;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        font-size: 16px;
        line-height: 24px;
        font-family: 'DejaVu Sans';
        color: #555;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }


    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }

    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
        background: #d5d5d5;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }

    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.item td{
        border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
        border-bottom: none;
    }

    .invoice-box table tr.total td.last {
        border-top: 2px solid #eee;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }

        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    .clientInfoos, .sellerInfoos{
        display: flex;
        justify-content: space-between;
    }
    .N_table , .N_td  {
        font-family: 'DejaVu Sans';
        border: 1px solid #efeaea;
        border-collapse: collapse;
    }
    .text-q {
        font-size: 9px;
        font-size: 9px;
        width: 120px;
        text-align: center;
        margin-top: 5px;
    }
</style>
